.App {
  text-align: center;
  background-color: #04082E;
  overflow: hidden;
  height: 100vh;
  max-height: -webkit-fill-available;
  -webkit-bleedingedgethingy: 1; 
  .navBar {
    padding: 1em;
    position: absolute;
    width: 100vw;
    z-index: 999;
    @media screen and (max-device-width: 850px) {
      width: 100%!important;
    }

    .App-logo {
      margin-left: 4em;
      @media screen and (max-device-width: 850px) {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        margin-left: 0;
      }
    }
    .menu {
      .item {
        color: #BEBFC4;
        font-weight: 600;
        transition: all .3s;
        @media only screen and (max-width: 600px) {
          display: none;
        }
        &:hover {
          color: #FFFFFF;
          &.active {
            color: #BEBFC4;
          }
        }
        &.active {
          color: #FFFFFF;
          @media only screen and (max-width: 600px) {
            display: block;
          }
        }
      }
    }

    
  }

  .header {
    height: 100%;
    background: url('../images/background-gradient.png') 100% 0 no-repeat;
    padding: 0 4em 0 2.3em;

    @media screen and (max-device-width: 850px) {
      background: url('../images/background-gradient.png') 75% 0 no-repeat;
      padding: 0;
      display: block;
      width: 100%;
      .widthResponsive {
        width: 80%;
        margin-left: auto;
        margin-top: 8vh;
      }

      .relative {
        margin-left: 0.8em;
      }

      .pointBg {
        width: 6em;
      }

      padding-top: 10em;
    }


    .photo_profil {
      width: 100%;
      @media screen and (max-device-width: 850px) {
        padding-right: 1em;
      }
      
    }

    h1 {
      background: linear-gradient(135.01deg, #35EEFE 9.79%, #7463EE 79.07%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      
      text-align: left;
      margin: 0;

      font-size: 6em;
      font-weight: 600;
      padding-bottom: 0.2em;

      @media screen and (max-device-width: 850px) {
        font-size: 3.3em;
      }


    }

    h3 {
      background: linear-gradient(135.01deg, #35EEFE 9.79%, #7463EE 79.07%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      
      font-size: 2.5em;
      text-align: left;
      font-weight: 500;
      padding-bottom: 0.2em;

      @media screen and (max-device-width: 850px) {
        padding-top: 1em;
        font-size: 1.35em;
      }
    }

    .description {
      color: #7463EE;
      font-size: 2.125em;
      margin-top: -1.5em;

      @media screen and (max-device-width: 850px) {
        font-size: 0.88em;
        margin-top: 0;
        margin-bottom: 3em;
        text-align: left;
      }

      .eiffel_tower {
        margin: 0 0.7em;
        width: 1.5em;
      }
    }

    .cta {
      .line {
        display: block;
        content: ' ';
        width: 2px;
        height: 2em;
        margin-left: 2em;
        background-color: #2E3256;
      }
      
      .see_project {
        margin-left: 1.1em;
        border: 3px solid;
        padding: 0.7em 1.5em 0.68em;
        border: 3px solid #5A9BF4;  
        border-radius: 30px;
        transition: all .3s;
        &:hover {
          background-color: #5A9BF4;
          color: #1D0B2F;
        }
      }
      .contact_me {
        margin-left: 1.5em;
        transition: all .3s;
        &:hover {
          opacity: 0.5;
        }
      }
    }
  }

  .footer {
    position: fixed;
    top: 96%;
    left: 50%;
    transform: translateX(-50%);
    a {
      padding-bottom: 1em;
      font-size: 0.9em;
      font-weight: 400;
      color: #7463EE;
    }
  }
}
